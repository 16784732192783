import { graphql, useStaticQuery } from 'gatsby';

const useHeaderAssets = () => {
  const data = useStaticQuery(graphql`
    query {
      headerNav: wpMenu(locations: { eq: HEADER_MENU }) {
        name
        menuItems {
          nodes {
            id
            label
            url
            target
            parentId
            childItems {
              nodes {
                id
                label
                url
                childItems {
                  nodes {
                    id
                    label
                    url
                  }
                }
              }
            }
          }
        }
      }
      mobileSubNav: wpMenu(locations: { eq: MOBILE_SUB_MENU }) {
        name
        menuItems {
          nodes {
            id
            label
            url
          }
        }
      }
    }
  `);

  // Only get top level items
  data.headerNav.menuItems.nodes = data.headerNav.menuItems.nodes.filter((n) => !n.parentId);
  data.mobileSubNav.menuItems.nodes = data.mobileSubNav.menuItems.nodes.filter((n) => !n.parentId);

  return data;
};

export default useHeaderAssets;
