import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { motion, AnimatePresence } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';
import IconChevron from '~/components/atoms/IconChevron';
import { objectPosition } from 'tailwindcss/defaultTheme';


export default function SubNav({ headerNav, setActive, active, overlayOpen, setOverlayOpen, navIndex }) {
  const [items, setItems] = useState(null);
  const [index, setIndex] = useState(0);
  const [SecondNav, setSecondNav] = useState(false)
  const [secondNavLabel, setSecondNavlabel] = useState('')
  const [secondNavItems, setSecondNavItems] = useState([''])

  useEffect(() => {
    setItems(headerNav.filter((item) => item.label === active).pop());

    headerNav.map((nav, i) => nav.label === active && setIndex(i));
    setSecondNav(false)
  }, [active]);

  return (
    <div className='absolute w-screen h-full lg:mt-20'>
      <div 
        onClick={() => {
              setActive('')
              setOverlayOpen(false)
        }}></div>
      <div className='flex flex-row w-fit-content'>
        <nav
          className={`w-100 xl:w-120 h-auto pt-8 z-20 flex flex-col px-20 pb-24 pl-10 bg-sand text-navy ${SecondNav ? 'shadow-boxfirst' : 'shadow-box'} ${navIndex === 1 && 'ml-28 xl:ml-36'}`}
        >
          <ul style={{ height: 'auto' }}>
            <AnimatePresence exitBeforeEnter={true}>
              {items?.childItems?.nodes?.map((item, i) => (
                <div className='flex flex-row w-full hover:bg-black hover:bg-opacity-5 hover:border-opacity-10 hover:border-black' onClick={() => {
                  if (item.childItems.nodes.length > 0 ) setSecondNav(!SecondNav)
                  setSecondNavItems(item.childItems.nodes)
                  setSecondNavlabel(item.label)
                }}>
                  <motion.li
                    key={item.url + i}
                    className="border-t border-b border-transparent "
                    initial={{ y: '6px', opacity: 0 }}
                    animate={{ y: 0, opacity: 1, transition: { duration: 0.2, delay: i / 15 } }}
                    exit={{ y: 0, opacity: 0, transition: { duration: 0.2, delay: i / 15 } }}
                  >
                    <Link
                      to={item.url}
                      className="block py-4 text-2xl font-medium tracking-tight transition-opacity duration-200 ease-in-out font-heading"
                    >
                      {item.label}
                    </Link>
                  </motion.li>
                  {(item.childItems.nodes.length > 0) && (
                    <div className='self-center'>
                      <IconChevron className={`ml-6 transform transition-transform duration-200 ease-in-out cursor-pointer -rotate-90 w-4 h-4 ${(SecondNav && secondNavLabel === item.label) && 'bg-yellow rounded-full'}`} />
                    </div>
                  )}
                </div>
              ))}
            </AnimatePresence>
          </ul>
          {items?.childItems?.nodes.length <= 4
            ?
              <div className="w-11/12 mx-auto mt-6">
                <AnimatePresence exitBeforeEnter={true}>
                  {index === 0 ? (
                    <motion.div
                      key={`nav-image-1`}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ type: 'tween', duration: 0.3 }}
                    >
                      <StaticImage
                        loading={'eager'}
                        src={'../../assets/images/resident-nav.png'}
                        objectFit="contain"
                        objectPosition="center"
                        alt="CityRelay"
                      />
                    </motion.div>
                  ) : (
                    <motion.div
                      key={`nav-image-2`}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ type: 'tween', duration: 0.3 }}
                    >
                      <StaticImage
                        loading={'eager'}
                        src={'../../assets/images/owner-nav.png'}
                        objectFit="contain"
                        objectPosition="center"
                        alt="CityRelay"
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            :
              ''
          }
        </nav>
        {SecondNav &&
          <motion.nav
          className={`w-100 xl:w-120 h-auto pt-8 mt-12 xl:mt-8 z-10 flex flex-col px-20 pb-24 pl-10 bg-sand-light text-navy shadow-boxsec`}
          initial={{ x: '-100%' }}
          animate={{ x: '0' }}
          exit={{ x: '-100%' }}
          transition={{ type: 'tween', duration: 0.3 }}
          >
            <ul style={{ height: 'auto' }}>
            <AnimatePresence exitBeforeEnter={true}>
              {secondNavItems.map((item, i) => (
                <div className='flex flex-row w-full hover:bg-black hover:bg-opacity-5 hover:border-opacity-10 hover:border-black'>
                  <motion.li
                    key={item.url + i}
                    className="border-t border-b border-transparent cursor-pointer"
                    initial={{ y: '6px', opacity: 0 }}
                    animate={{ y: 0, opacity: 1, transition: { duration: 0.2, delay: i / 15 } }}
                    exit={{ y: 0, opacity: 0, transition: { duration: 0.2, delay: i / 15 } }}
                  >
                    <Link
                      to={item.url}
                      className='block py-4 text-xl font-medium tracking-tight transition-opacity duration-200 ease-in-out font-heading'
                    >
                      {item.label}
                    </Link>
                  </motion.li>
                </div>
              ))}
            </AnimatePresence>
          </ul>
          </motion.nav>
        }
      </div>
    </div>
  );
}
