import { graphql, useStaticQuery } from 'gatsby';

const useLegalAssets = () => {
  const data = useStaticQuery(graphql`
    query {
      legalNav: wpMenu(locations: { eq: LEGAL_MENU }) {
        name
        menuItems {
          nodes {
            id
            label
            url
            parentId
          }
        }
      }
    }
  `);

  // Only get top level items
  data.legalNav.menuItems.nodes = data.legalNav.menuItems.nodes.filter((n) => !n.parentId);

  return data;
};

export default useLegalAssets;
