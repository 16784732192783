// ConsentModeScript.js
import React, { useEffect } from 'react';

const ConsentModeScript = () => {
  useEffect(() => {
    // Ensure that the script only runs on the client-side
    if (typeof window !== 'undefined') {
      // Define dataLayer variable at the top level
      window.dataLayer = window.dataLayer || [];
      function gtag() { window.dataLayer.push(arguments); }

      if (localStorage.getItem('consentMode') === null) {
        gtag('consent', 'default', {
          'ad_storage': 'denied',
          'ad_user_data': 'denied',
          'ad_personalization': 'denied',
          'analytics_storage': 'denied',
          'functionality_storage': 'denied',
          'personalization_storage': 'denied',
          'security_storage': 'denied',
        });
      } else {
        // Retrieve consent mode from local storage and apply it
        gtag('consent', 'default', JSON.parse(localStorage.getItem('consentMode')));
      }
    }
  }, []);

  return null; // This component doesn't render anything, it just runs the script
};

export default ConsentModeScript;
