import React from 'react'

export default function IconArrow({color = 'black', className}) {
  return (
    <svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M6.83301 -4.76837e-07L6.83301 20" stroke="currentColor" strokeWidth="1.5"/>
      <path d="M12.6667 14.1667L6.83333 20L1 14.1667" stroke="currentColor" strokeWidth="1.5"/>
    </svg>
  )
}
