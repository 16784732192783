import { graphql, useStaticQuery } from 'gatsby';
import globalState from '~/store/index';

const useCategories = () => {
  const [ resourceType ] = globalState('resourceType');

  const data = useStaticQuery(graphql`
    query {
      allWpCategory(filter: { name: { nin: [ "Uncategorized", "Uncategorised"] } }) {
        nodes {
          id
          slug
          name
          uri
        }
      }
    }
  `);

  const partners = [
    'industry-insights',
    'landlord-advice',
    'hosting-tips',
    'company-news',
    'podcast',
    'employee-interview',
    'research-analysis',
    'sustainability',
  ];

  const residential = [
    'company-news',
    'guest-travel-tips',
    'local-highlights',
  ];

  const cats = data?.allWpCategory?.nodes;

  if (resourceType === 'residential') {
    return cats.filter(c => residential.includes(c.slug));
  } else if (resourceType === 'partner') {
    return cats.filter(c => partners.includes(c.slug));
  } else {
    return cats;
  }
};

export default useCategories;
