export const helpFeaturesLabels = {
    "en": {
      "subheader": "We help with:",
      "features": [
        { "title": "Listings" },
        { "title": "Management" },
        { "title": "Sourcing" }
      ]
    },
    "fr": {
      "subheader": "Nous aidons avec:",
      "features": [
        { "title": "Annonces" },
        { "title": "Gestion" },
        { "title": "Approvisionnement" }
      ]
    },
    "es": {
      "subheader": "Te ayudamos con:",
      "features": [
        { "title": "Listados" },
        { "title": "Gestión" },
        { "title": "Abastecimiento" }
      ]
    }
  }