import { Link } from 'gatsby'
import Arrow from '~/components/atoms/Arrow'
import React from 'react'

export default function ButtonArrow({ url, title, target, type = 'primary', inverted = false, className, submitting, ...other }) {

  if (!url && !title)
    return null

  const Tag = url?.length > 0 ? Link : 'button';

  return (
    <Tag to={url} className={` ${className}`} target={target ? target : '_self'} {...other}>
      <Arrow />
    </Tag>
  )
}
