import React from 'react'
import FindCta from '~/components/molecules/FindCta';
import { StaticImage } from 'gatsby-plugin-image';

export default function FooterFindCta() {
  return (
    <div className="lg:flex items-center">
      <div className="mb-20">
        <FindCta subheading="Find your new home" heading="Start living today" subheadingClassName="text-blue" />
      </div>
      <div className="-ml-6 -mr-6 lg:ml-auto mb-10 lg:mb-12 lg:-mr-40">
        <StaticImage src={'../../assets/images/footer-illustration.png'} className="w-full lg:w-[36rem]" alt="CityRelay" />
      </div>
    </div>
  )
}
