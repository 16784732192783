import { Link, navigate } from 'gatsby';
import { useDebounce } from 'react-use';
import React, { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';
import Logo from '~/components/atoms/Logo';
import Button from '~/components/atoms/Button';
import MobileNav from '~/components/molecules/MobileNav';
import SubNav from '~/components/molecules/SubNav';
import IconChevron from '~/components/atoms/IconChevron';
import useCategories from "~/hooks/useCategories"
import globalState from '~/store/index';
import useHeaderAssets from '~/hooks/useHeaderAssets';
import CitiesDropdown from '../molecules/CitiesDropdown';
import { headerNavItems, mobileSubNavItems, valuationButtonLabels } from '~/data/translations/headerLabels';

export default function Header({
  headerWhite,
  headerBgWhite,
  headerSticky,
  showResourcesNav,
  showLogin,
  active,
  setActive,
  overlayOpen,
  setOverlayOpen,
  pageLanguage,
}) {
  const categories = useCategories();

  const [search, setSearch] = globalState('search');
  const [navIsOpen, setNavIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [navIndex, setNavIndex] = useState(0)

  useDebounce(() => {
    if (search.length) {
      return navigate('/search/');
    }
  }, 2000, [search]);


  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () => setScrolled(window.pageYOffset > 10), { passive: true });
    }
  }, []);

  const headerAssets = useHeaderAssets();
  const isEnglishPage = (pageLanguage ?? 'en') === 'en';

  const headerNav = headerNavItems[pageLanguage ?? 'en'] ?? headerAssets.headerNav;
  const mobileSubNav = mobileSubNavItems[pageLanguage ?? 'en'] ?? headerAssets.mobileSubNav;
  const valuationButtonLabel = valuationButtonLabels[pageLanguage ?? 'en']

  return (
    <header className={`${headerSticky ? 'sticky' : 'fixed'} top-0 left-0 right-0 z-30 transition-colors duration-300 ${((scrolled && !navIsOpen) || headerBgWhite) ? 'bg-white drop-shadow-sm' : ''} ${navIsOpen ? 'bg-sand' : ''} `}>
      <div className={`flex items-center px-5 lg:px-10 h-14 lg:h-20 border-b border-black border-opacity-5 z-10 relative lg:border-b-0`}>
        <nav className="mr-auto w-full hidden lg:block">
          <ul className="inline-flex items-center relative mr-auto">
            {headerNav.menuItems.nodes.map((item, i) => (
              <li key={item.id} className={active === item.label ? 'mr-4 xl:mr-6 last:mr-0' : "mr-4 xl:mr-6 last:mr-0"}>
                <Link
                  onClick={(e) => {
                    setNavIndex(i)
                    setOverlayOpen(active === item.label ? !overlayOpen : item.label)
                    if (item.url === '#') {
                      e.preventDefault();
                    }
                    (item.childItems.nodes.length > 0) && setActive(active === item.label ? '' : item.label)
                  }}
                  to={item.url}
                  target={item.target ? item.target : '_self'}
                  className={`tracking-tight hover:opacity-50 transition-all duration-200 ease-in-out relative z-20 flex items-center ${active === item.label && 'text-blue'} ${(headerWhite && active === '' && !scrolled) ? 'text-white' : 'text-navy'}`}
                >
                  {item.label}
                  {(item.childItems.nodes.length > 0) && (
                    <IconChevron className={`ml-2 transform transition-transform duration-200 ease-in-out ${active === item.label && 'rotate-180'}`} />
                  )}
                </Link>
              </li>
            ))}

            <AnimatePresence>
              {active !== '' && <SubNav headerNav={headerNav.menuItems.nodes} setActive={setActive} active={active} overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} navIndex={navIndex} />}
            </AnimatePresence>
          </ul>
        </nav>
        <Link to="/" className={`block w-32 lg:w-40 lg:flex-grow lg:flex-shrink-0 transition-colors duration-300 ${(headerWhite && !navIsOpen && !scrolled) ? 'text-white' : 'text-navy'}`}>
          <Logo className="w-full h-auto" />
        </Link>
        <div className="ml-auto w-full justify-end hidden lg:flex space-x-2">
          <CitiesDropdown headerWhite={headerWhite} scrolled={scrolled} language={pageLanguage} />
          {showLogin ? (
            <>
              {isEnglishPage && (
                <Button type={`secondary`} title="+44 20 3865 0599" className="!py-4" inverted={headerWhite ? (scrolled ? false : true) : false} />
              )}
              <Button url={valuationButtonLabel.url} title={valuationButtonLabel.label} className="!py-4" />
            </>
          ) : (
            <>
              {isEnglishPage && (
                <Button url="https://stay.cityrelay.com/flat-listing" title="Find my home" className="!py-4" />
              )}
            </>
          )}
        </div>
        <div className="ml-auto lg:hidden flex flex-row">
          <CitiesDropdown headerWhite={headerWhite} scrolled={scrolled} navIsOpen={navIsOpen} language={pageLanguage} />
          <button onClick={() => setNavIsOpen(!!!navIsOpen)} className={`flex items-center ${navIsOpen && 'active'}`}>
            <span className={`burger ${(headerWhite && !navIsOpen && !scrolled) && 'burger--white'}`}>
              <span></span>
              <span></span>
              <span></span>
              <span className="sr-only">Open menu</span>
            </span>
          </button>
        </div>
      </div>

      {showResourcesNav && (
        <div className={`flex items-center relative z-[-1] px-5 lg:px-10 border-b border-t border-t-[#EFEEEA] border-b-[#EFEEEA] overflow-hidden transition-colors duration-300 ${scrolled && 'bg-white'}`}>
          <nav className="mr-auto w-full hidden lg:block py-4 overflow-x-auto">
            <ul className="inline-flex items-center relative mr-auto">
              {categories.map(({ name, uri }, i) => (
                <li key={uri + i} className="mr-4 xl:mr-6">
                  <Link
                    to={uri}
                    className={`whitespace-nowrap tracking-tight hover:opacity-50 text-sm transition-all duration-200 ease-in-out relative z-20 flex items-center ${(headerWhite && active === '' && !scrolled) ? 'text-white' : 'text-navy'}`}
                    dangerouslySetInnerHTML={{ __html: name }}
                  />
                </li>
              ))}
            </ul>
          </nav>
          <div className="lg:ml-auto lg:border-l h-full border-l-[#EFEEEA] py-4 ">
            <form onSubmit={e => { e.preventDefault(); navigate('/search/'); }} className="pl-5 flex items-center">
              <StaticImage src={'../../assets/images/search.png'} className="w-6 h-6 mr-1" objectFit="contain" objectPosition="center" alt="CityRelay" />
              <input onChange={e => setSearch(e.target.value)} value={search} type="text" placeholder="Search..." className="inline-block bg-transparent" />
            </form>
          </div>
        </div>
      )}
      <AnimatePresence>
        {navIsOpen && <MobileNav mobileSubNav={mobileSubNav} headerNav={headerNav} />}
      </AnimatePresence>
    </header>
  );
}
