import { Link } from 'gatsby'
import React from 'react'

export default function TextLink({ className, lineClassName, title, url = '#', target }) {
  //const Tag = url.length > 0 && url !== '#' ? Link : 'button';
  const Tag = ({ ...props }) => {
    if (url.length > 0 && url !== '#') {
      return (
        <Link to={url} target={target ? target : '_self'} {...props}>{title}</Link>
      )
    }
    else {
      return (
        <p {...props}>
          {title}
        </p>
      )
    }
  }
  return (
    <Tag className={`group overflow-hidden py-1 text-current uppercase font-heading font-bold tracking-wide transition-colors duration-200 ease-in-out inline-block text-sm relative text-link ${className}`} />
  )
}
