import useFetch from 'use-http';
import Cookies from "js-cookie";

const submitForm = ({ formId, portalId = '7026924', setIsSubmitted, fields = [] }) => {
  // console.log('formId=', formId);

  const isBrowser = typeof window !== "undefined";
  const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

  // eslint-disable-next-line
  const { post, error, response, loading } = isBrowser ? useFetch(postUrl) : {};

  return {
    error,
    response,
    loading,
    post: async (values) => {

      if (formId == '070c7fb5-f573-4378-b9fb-0b0c1be1551b') {
        // console.log('your form here');
        // console.log('values=', values);
        if (
          values.email == ''
          || values.firstname == ''
          || values.lastname == ''
          || values.property_address == ''
          || values.phone == ''
          || values.of_properties == ''
          || values.number_of_bedrooms == ''
        ) {
          // console.log('stop here');
          return;
        }
      }

      const hutk = isBrowser ? Cookies.get("hubspotutk") : null;
      const pageUri = isBrowser ? window.location.href : null;
      const pageName = isBrowser ? document.title : null;
      const pageUrl = isBrowser ? window.location.pathname : null;

      const fields = [...Object.keys(values)].map((key) => {
        return {
          name: key,
          value: values[key],
        };
      });

      const data = {
        submittedAt: Date.now(),
        fields,
        context: {
          hutk,
          pageUri,
          pageName,
        },
      };

      await post(data);

      if (response.ok === true && typeof setIsSubmitted !== 'undefined') {
        setIsSubmitted(true);
      }

      if (typeof window?.dataLayer?.push !== 'undefined') {
        window.dataLayer.push({ event: 'form-submit', pageUrl });
      }
    },
  };
}

export default submitForm;
