import { graphql, useStaticQuery } from 'gatsby';

const useFooterAssets = () => {
  const data = useStaticQuery(graphql`
    query {
      footerNav: wpMenu(locations: { eq: FOOTER_MENU }) {
        name
        menuItems {
          nodes {
            id
            label
            url
            parentId
            childItems {
              nodes {
                id
                label
                url
              }
            }
          }
        }
      }
      neighbourhoodsNav: wpMenu(locations: {eq: NEIGHBOURHOODS_MENU}) {
        name
        menuItems {
          nodes {
            id
            label
            url
            parentId
            childItems {
              nodes {
                id
                label
                url
              }
            }
          }
        }
      }
    }
  `);

  // Only get top level items
  data.footerNav.menuItems.nodes = data.footerNav.menuItems.nodes.filter((n) => !n.parentId);
  data.neighbourhoodsNav.menuItems.nodes = data.neighbourhoodsNav.menuItems.nodes.filter((n) => !n.parentId);

  return data;
};

export default useFooterAssets;
