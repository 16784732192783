export const footerLabels = {
    "en": {
        "newsletterHeader": "Subscribe for updates",
        "newsletterHeaderSubmitted": "Thank you for joining our newsletter",
        "newsletterLabel": "Sign up for our newsletter to receive up to date content from City Relay.",
        "blogsHeader": "From the blog",
        "rightsReserved": "City Relay. All Rights Reserved.",
    },
    "fr": {
        "newsletterHeader": "Abonnez-vous pour les mises à jour",
        "newsletterHeaderSubmitted": "Merci de vous être inscrit à notre newsletter",
        "newsletterLabel": "Inscrivez-vous à notre newsletter pour recevoir du contenu actualisé de City Relay.",
        "blogsHeader": "Du blog",
        "rightsReserved": "City Relay. Tous les droits sont réservés.",
    },
    "es": {
        "newsletterHeader": "Suscríbete para recibir actualizaciones",
        "newsletterHeaderSubmitted": "Gracias por unirte a nuestro boletín",
        "newsletterLabel": "Suscríbete a nuestro boletín para recibir contenido actualizado de City Relay.",
        "blogsHeader": "Desde el blog",
        "rightsReserved": "City Relay. Todos los derechos reservados.",
    }
}

export const footerMenuItemsNodes = {
    "fr": [
        // {
        //     childItems: {
        //         nodes: [
        //             {
        //                 id: 'cG9zdDoyODcy',
        //                 label: 'Expérience',
        //                 url: "https://cityrelay.com/experience/",
        //             },
        //             {
        //                 id: 'cG9zdDoyODc0',
        //                 label: 'Trouvez votre maison',
        //                 url: "https://stay.cityrelay.com/flat-listing",
        //             },
        //             {
        //                 id: 'cG9zdDoyODU4',
        //                 label: 'Ressources résidentes',
        //                 url: 'https://cityrelay.com/resources/',
        //             },
        //             {
        //                 id: 'cG9zdDo0ODQ0',
        //                 label: 'Protection des locataires',
        //                 url: 'https://cityrelay.com/certificate/',
        //             },
        //         ]
        //     },
        //     id: 'cG9zdDoyODQy',
        //     label: 'Résidents',
        //     parentId: null,
        //     url: '#',
        // },
        {
            childItems: {
                nodes: [
                    {
                        id: 'cG9zdDoyODYy',
                        label: 'Comment ça fonctionne',
                        url: "https://cityrelay.com/fr/pour-les-proprietaires-de-biens-immobiliers-a-paris/",
                    },
                    {
                        id: 'cG9zdDoyODY4',
                        label: 'Évaluation locative',
                        url: "https://cityrelay.com/fr/estimation-de-location-a-paris/",
                    },
                    // {
                    //     id: 'cG9zdDo1MDUz',
                    //     label: 'Brochure',
                    //     url: "https://cityrelay.com/City-Relay-Brochure-091121.pdf",
                    // },
                    {
                        id: 'cG9zdDozNzY2',
                        label: 'Gestion des locations courtes',
                        url: "https://cityrelay.com/fr/gestion-airbnb-paris/",
                    },
                    // {
                    //     id: 'cG9zdDo0NDY0',
                    //     label: 'Gestion des longs séjours',
                    //     url: "https://cityrelay.com/london-long-let-management/",
                    // },
                    // {
                    //     id: 'cG9zdDoyODY2',
                    //     label: 'Nos partenaires',
                    //     url: "https://cityrelay.com/partners/",
                    // },
                    {
                        id: 'cG9zdDo0NDYy',
                        label: 'Nos services',
                        url: "https://cityrelay.com/fr/les-agents-de-location-a-paris/",
                    },
                    // {
                    //     id: 'cG9zdDo0NDY4',
                    //     label: 'Gestion des blocs',
                    //     url: "https://cityrelay.com/london-block-management/",
                    // },
                ]
            },
            id: 'cG9zdDoyODQ0',
            label: 'Propriétaires',
            parentId: null,
            url: '#',
        },
        // {
        //     childItems: {
        //         nodes: [
        //             {
        //                 id: 'cG9zdDoyODQ4',
        //                 label: "À propos",
        //                 url: "https://cityrelay.com/about/",
        //             },
        //             {
        //                 id: 'cG9zdDoyODUw',
        //                 label: 'Carrières',
        //                 url: "https://cityrelay.com/careers/",
        //             },
        //             {
        //                 id: 'cG9zdDoyODUy',
        //                 label: 'Contact',
        //                 url: "https://cityrelay.com/contact/",
        //             },
        //             {
        //                 id: 'cG9zdDoyODU2',
        //                 label: 'Durabilité',
        //                 url: "https://cityrelay.com/sustainability/",
        //             },
        //             {
        //                 id: 'cG9zdDoyODU0',
        //                 label: 'FAQs',
        //                 url: "https://cityrelay.com/faqs/",
        //             },
        //         ]
        //     },
        //     id: 'cG9zdDoyODQ2',
        //     label: 'Entreprise',
        //     parentId: null,
        //     url: '#',
        // },
    ],
    "es": [
        // {
        //     childItems: {
        //         nodes: [
        //             {
        //                 id: 'cG9zdDoyODcy',
        //                 label: 'Experiencia',
        //                 url: "https://cityrelay.com/experience/",
        //             },
        //             {
        //                 id: 'cG9zdDoyODc0',
        //                 label: 'Encuentra tu hogar',
        //                 url: "https://stay.cityrelay.com/flat-listing",
        //             },
        //             {
        //                 id: 'cG9zdDoyODU4',
        //                 label: 'Recursos para residentes',
        //                 url: 'https://cityrelay.com/resources/',
        //             },
        //             {
        //                 id: 'cG9zdDo0ODQ0',
        //                 label: 'Protección de inquilinos',
        //                 url: 'https://cityrelay.com/certificate/',
        //             },
        //         ]
        //     },
        //     id: 'cG9zdDoyODQy',
        //     label: 'Residentes',
        //     parentId: null,
        //     url: '#',
        // },
        {
            childItems: {
                nodes: [
                    {
                        id: 'cG9zdDoyODYy',
                        label: 'Cómo funciona',
                        url: "https://cityrelay.com/es/para-propietarios-barcelona/",
                    },
                    {
                        id: 'cG9zdDoyODY4',
                        label: 'Valoración de alquiler',
                        url: "https://cityrelay.com/es/estimacion-de-alquiler-en-barcelona/",
                    },
                    // {
                    //     id: 'cG9zdDo1MDUz',
                    //     label: 'Brochure',
                    //     url: "https://cityrelay.com/City-Relay-Brochure-091121.pdf",
                    // },
                    {
                        id: 'cG9zdDozNzY2',
                        label: 'Gestión de estancias cortas',
                        url: "https://cityrelay.com/es/gestion-alquiler-turistico-barcelona/",
                    },
                    // {
                    //     id: 'cG9zdDo0NDY0',
                    //     label: 'Gestión de alquileres prolongados',
                    //     url: "https://cityrelay.com/london-long-let-management/",
                    // },
                    // {
                    //     id: 'cG9zdDoyODY2',
                    //     label: 'Nuestros socios',
                    //     url: "https://cityrelay.com/partners/",
                    // },
                    {
                        id: 'cG9zdDo0NDYy',
                        label: 'Nuestros servicios',
                        url: "https://cityrelay.com/es/gestion-de-alquileres-en-barcelona/",
                    },
                    // {
                    //     id: 'cG9zdDo0NDY4',
                    //     label: 'Gestión de bloques',
                    //     url: "https://cityrelay.com/london-block-management/",
                    // },
                ]
            },
            id: 'cG9zdDoyODQ0',
            label: 'Propietarios',
            parentId: null,
            url: '#',
        },
        // {
        //     childItems: {
        //         nodes: [
        //             {
        //                 id: 'cG9zdDoyODQ4',
        //                 label: "Acerca de",
        //                 url: "https://cityrelay.com/about/",
        //             },
        //             {
        //                 id: 'cG9zdDoyODUw',
        //                 label: 'Carreras',
        //                 url: "https://cityrelay.com/careers/",
        //             },
        //             {
        //                 id: 'cG9zdDoyODUy',
        //                 label: 'Contacto',
        //                 url: "https://cityrelay.com/contact/",
        //             },
        //             {
        //                 id: 'cG9zdDoyODU2',
        //                 label: 'Sostenibilidad',
        //                 url: "https://cityrelay.com/sustainability/",
        //             },
        //             {
        //                 id: 'cG9zdDoyODU0',
        //                 label: 'FAQs',
        //                 url: "https://cityrelay.com/faqs/",
        //             },
        //         ]
        //     },
        //     id: 'cG9zdDoyODQ2',
        //     label: 'Empresa',
        //     parentId: null,
        //     url: '#',
        // },
    ]
}

export const legalNavMenuItemsNodes = {
    "fr": [
        {
            id: 'cG9zdDoyODA1',
            label: 'Politique de confidentialité',
            parentId: null,
            url: "https://cityrelay.com/privacy-policy/",
        },
        {
            id: 'cG9zdDoyODA2',
            label: 'Politique relative aux cookies',
            parentId: null,
            url: "https://cityrelay.com/cookie-policy/",
        },
        {
            id: 'cG9zdDoyODA3',
            label: 'Conditions du propriétaire',
            parentId: null,
            url: "https://cityrelay.com/property-owner-terms/",
        },
        {
            id: 'cG9zdDoyODA4',
            label: 'Conditions pour les résidents',
            parentId: null,
            url: "https://cityrelay.com/resident-terms/",
        },
    ],
    "es": [
        {
            id: 'cG9zdDoyODA1',
            label: 'Política de privacidad',
            parentId: null,
            url: "https://cityrelay.com/privacy-policy/",
        },
        {
            id: 'cG9zdDoyODA2',
            label: 'Política de cookies',
            parentId: null,
            url: "https://cityrelay.com/cookie-policy/",
        },
        {
            id: 'cG9zdDoyODA3',
            label: 'Condiciones para propietarios',
            parentId: null,
            url: "https://cityrelay.com/property-owner-terms/",
        },
        {
            id: 'cG9zdDoyODA4',
            label: 'Condiciones para residentes',
            parentId: null,
            url: "https://cityrelay.com/resident-terms/",
        },
    ],
}
