import React from 'react'
import Button from '~/components/atoms/Button'

export default function FindCta({className, subheadingClassName, subheading, heading}) {

  return (
    <div className={`${className}`}>
      {subheading && <h6 className={`text-h6 mb-4 ${subheadingClassName}`}>{subheading}</h6>}
      {heading && <h4 className="text-h2 mb-6">{heading}</h4>}
      <div  className="flex flex-col sm:flex-row mb-8">
        <Button url="https://stay.cityrelay.com/flat-listing" title="Find my home" className="inline-flex items-center whitespace-nowrap" />
      </div>
    </div>
  )
}
