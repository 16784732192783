import { useLocation } from "react-use"

const usePageLanguage = () => {
  
    const { pathname } = useLocation();
    
    if(pathname?.includes('/fr/')){
        return 'fr'
    };

    if(pathname?.includes('/es/')){
        return 'es'
    }

    return 'en'
    
}

export default usePageLanguage