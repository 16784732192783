import React, { useState, useEffect } from 'react';
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo';
import useSiteInfoAssets from '~/hooks/useSiteInfoAssets';
import Footer from '~/components/organisms/Footer';
import Header from '~/components/organisms/Header';
import CookiePolicy from '~/components/organisms/CookiePolicy';
import ConsentModeScript from './ConsentModeScript'; // Import ConsentModeScript component

const Layout = ({
  children,
  location,
  className = '',
  backgroundColour = 'sand-light',
  headerWhite = false,
  headerBgWhite = false,
  headerSticky = false,
  showResourcesNav = false,
  showLogin = false,
  footerCtaType,
  footerResourcesType,
  pageLanguage = 'en'
}) => {
  const seo = useSiteInfoAssets();

  const [active, setActive] = useState('');
  const [overlayOpen, setOverlayOpen] = useState(false)

  let backgroundColourClass = 'bg-sand-light'
  if (backgroundColour === 'navy') {
    backgroundColourClass = 'bg-navy text-white'
  }

  return (
    <>
      <style>{`
      #rcc-confirm-button {
        display: none;
      }
    `}</style>
      <SEOContext.Provider value={{ global: seo }}>
        <div className={`${backgroundColourClass} ${className} `}>
          <Header headerWhite={headerWhite} headerSticky={headerSticky} headerBgWhite={headerBgWhite} showResourcesNav={showResourcesNav} showLogin={showLogin} active={active} setActive={setActive} overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} pageLanguage={pageLanguage} />
          <div
            onClick={() => {
              setActive('')
              setOverlayOpen(false)
            }}>
            <main>{children}</main>
            <Footer footerCtaType={footerCtaType} footerResourcesType={footerResourcesType} pageLanguage={pageLanguage} />
          </div>
        </div>
        <div className="h-full h-1/2 h-1/3 h-2/3 h-1/4 h-3/4 h-5/6 bg-sand bg-navy text-left text-center text-h1 text-h2 text-h3 text-h4 text-h5 text-h6 bg-rose col-span-3 col-span-4" style={{ display: 'none' }}></div>
        { <ConsentModeScript /> }
        {<CookiePolicy /> }
      </SEOContext.Provider>
    </>
  );
};

export default Layout;
