import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import React, { useState } from 'react'
import IconChevron from '~/components/atoms/IconChevron';
import MobileSubNavAux from './MobileSubNavAux';


export default function SubNav({items = [] }) {
  const [SecondNav, setSecondNav] = useState(false)
  const [secondNavLabel, setSecondNavlabel] = useState('')
  const [secondNavItems, setSecondNavItems] = useState([''])

  return (
    <motion.nav
      className="flex flex-col justify-center pl-10 mt-5 l"
      initial={{ opacity: '0' }}
      animate={{ opacity: '1' }}
      exit={{ opacity: '0' }}
      transition={{ type: 'tween', duration: 0.5 }}
    >
      <ul>
        {items.map((item, i) => (
          <div className={`flex flex-row mb-4 ${(SecondNav && secondNavLabel === item.label) && ' hover:opacity-50'}`} onClick={() => {
            setSecondNav(!SecondNav)
            setSecondNavItems(item.childItems.nodes)
            setSecondNavlabel(item.label)
          }}>
            <li key={i} className="">
              <Link to={item.url} className="tracking-tight transition-opacity duration-200 ease-in-out font-normal font-body">{item.label}</Link>
            </li>
            {(item.childItems.nodes.length > 0) && (
              <div className='self-center'>
                <IconChevron className={`ml-4 transform transition-transform duration-200 ease-in-out cursor-pointer -rotate-90 w-4 h-4 `} />
              </div>
            )}
          </div>
        ))}
      </ul>
      {SecondNav && <MobileSubNavAux secondNavLabel={secondNavLabel} secondNavItems={secondNavItems} SecondNav={SecondNav} setSecondNav={setSecondNav} />}
    </motion.nav>
  )
}
