import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';

export default function Image({data, className = '', objectFit = 'cover', objectPosition = 'center', loading = 'lazy'}) {
  return (
    <GatsbyImage 
      className={className} 
      image={data?.localFile?.childImageSharp?.gatsbyImageData} 
      alt={data?.altText ? data?.altText : 'CityRelay'} 
      objectFit={objectFit} 
      objectPosition={objectPosition} 
      loading={loading} 
    />
  )
}
