import React from 'react';
import { useField } from 'formik';

export default function RadioField(props) {
  const { label, divclassname, labelClassName, inputClassName, ...other } = props;
  const [field, meta, helpers] = useField(props);
  const isInvalid = meta.touched && meta.error;

  const name = props.name;

  return (
    <label
      className={`flex items-center relative group  ${divclassname} ${
        !isInvalid ? 'border-grey focus-within:border-white' : 'border-[#ff0000]'
      }`}
    >
      <input
        name={name}
        type="radio"
        className={`w-5 h-5 border bg-transparent rounded-full appearance-none outline-none group-hover:border-opacity-100 focus:border-opacity-100 checked:border-opacity-100 transition-colors duration-200 ease-in-out  border-white  ${inputClassName} ${
          !isInvalid ? '' : '!border-error'
        }`}
        {...field}
        {...other}
      />
      <span className={`!text-sm cursor-pointer pl-2 ${labelClassName}`}>{label}</span>
    </label>
  );
}
