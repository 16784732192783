import React, { useState, useEffect } from 'react';
import CookieConsent, { Cookies } from "react-cookie-consent";

export default function CookiePolicy() {
  const [consentModeStorage, setConsentBtnChosen] = useState(false);

  useEffect(() => {
    const consentModeStorage = localStorage.getItem('consentMode');
    if (consentModeStorage !== null) {
      setConsentBtnChosen(true);
    }
  }, []);

  const handleAccept = () => {
    setConsentBtnChosen(true);
    if (typeof window !== 'undefined') {
      // Define dataLayer variable at the top level
      window.dataLayer = window.dataLayer || [];
      function gtag() { window.dataLayer.push(arguments); }
      const consentMode = {
        'functionality_storage': 'granted',
        'security_storage': 'granted',
        'ad_storage': 'granted',
        'analytics_storage': 'granted',
        'personalization': 'granted'
      };
      let consent = {
        'ad_storage': 'granted',
        'ad_user_data': 'granted',
        'ad_personalization': 'granted',
        'analytics_storage': 'granted',
        'functionality_storage': 'granted',
        'personalization_storage': 'granted',
        'security_storage': 'granted',
      };
      gtag('consent', 'update', consent);
      localStorage.setItem('consentMode', JSON.stringify(consentMode));
    }
  };

  const handleReject = () => {
    setConsentBtnChosen(true);

    if (typeof window !== 'undefined') {
      // Define dataLayer variable at the top level
      window.dataLayer = window.dataLayer || [];
      function gtag() { window.dataLayer.push(arguments); }
      const consentMode = {
        'functionality_storage': 'denied',
        'security_storage': 'denied',
        'ad_storage': 'denied',
        'analytics_storage': 'denied',
        'personalization': 'denied'
      };
      let consent = {
        'ad_storage': 'denied',
        'ad_user_data': 'denied',
        'ad_personalization': 'denied',
        'analytics_storage': 'denied',
        'functionality_storage': 'denied',
        'personalization_storage': 'denied',
        'security_storage': 'denied',
      };
      gtag('consent', 'update', consent);
      localStorage.setItem('consentMode', JSON.stringify(consentMode));
    }
  };

  return (
    <div>
      {!consentModeStorage && (
        <CookieConsent
          disableStyles={true}
          location="bottom"
          cookieName="gatsby-gdpr-google-tagmanager"
          containerClasses="bg-policy-container text-white w-full h-48 text-base fixed left-0 bottom-0 z-50 md:pr-28 md:pl-20 xl:pl-0 xxl:pl-20 md:h-28 md:justify-between lg:justify-start items-center"
          expires={150}
        >
          <div className="w-full md:w-3/4 lg:w-3/4 xl:w-3/4 self-center mt-4 ml-4 lg:mr-12 xl:mr-0 xxl:mr-12">
            We use cookies to give you the best online experience and to keep our site reliable and secure. Read more about it <a className='underline' target="_blank" href="https://cityrelay.com/cookie-policy/">here</a>.
          </div>
          <div className="flex items-center w-full md:w-3/4 lg:w-3/4 xl:w-3/4 self-center mt-2 ml-4 lg:mr-12 xl:mr-0 xxl:mr-12">
            <button
              className="bg-flex bg-yellow text-black cursor-pointer flex items-center justify-center px-4 py-2.5 mb-4 mt-0 text-xs w-20 h-8 font-bold md:text-base md:w-28 md:h-12"
              style={{ background: 'rgb(255, 212, 45)', height: '2.5rem', marginTop: '-0.5rem' }}
              onClick={handleAccept}
            >
              ACCEPT
            </button>
            <button
              className="bg-flex bg-trasnaprent text-white cursor-pointer flex items-center justify-center px-4 py-2.5 mb-4 text-xs w-20 h-8 font-bold md:text-base md:w-28 md:h-12"
              onClick={handleReject} style={{ height: '2.5rem', marginTop: '-0.5rem' }}
            >
              REJECT
            </button>
          </div>
        </CookieConsent>
      )}
    </div>
  );
}
