export const rentalCtaLabels = {
    "en": {
        "postcode": "Postcode",
        "buttonLabel": "Get Rental Estimate",
        "subheading": "Get an estimate",
        "heading": "See what your property can achieve with City Relay"
    },
    "fr": {
        "postcode": "Code postal",
        "buttonLabel": "Obtenir une estimation de location",
        "subheading": "Obtenir une estimation",
        "heading": "Découvrez ce que votre propriété peut réaliser avec City Relay"
    },
    "es": {
        "postcode": "Código postal",
        "buttonLabel": "Obtener cotización de alquiler",
        "subheading": "Obtener una estimación",
        "heading": "Descubre lo que tu propiedad puede lograr con City Relay"
    }
}