import { Link, useStaticQuery, graphql } from 'gatsby';
import React, { useState } from 'react';
import Arrow from '~/components/atoms/Arrow';
import IconChevron from '~/components/atoms/IconChevron';
import IconSocial from '~/components/atoms/IconSocial';
import FooterNav from '~/components/molecules/FooterNav';
import PostPreview from '~/components/molecules/PostPreview';
import NewsletterSubscribe from '~/components/molecules/NewsletterSubscribe';
import FooterFindCta from '~/components/molecules/FooterFindCta';
import FooterRentalCta from '~/components/molecules/FooterRentalCta';
import useFooterAssets from '~/hooks/useFooterAssets';
import useLegalAssets from '~/hooks/useLegalAssets';
import { footerLabels, footerMenuItemsNodes, legalNavMenuItemsNodes } from '~/data/translations/footerLabels';

const Footer = ({ footerCtaType, footerResourcesType, pageLanguage }) => {
  const data = useStaticQuery(graphql`
    query {
      residential:allWpPost(
        limit: 2,
        sort: { fields: date, order: DESC },
        filter: {
          categories: { nodes: { elemMatch: { name:{ nin: [ "Uncategorized", "Uncategorised"] } }}},
          resourceTypes: { nodes:{ elemMatch:{ slug:{ eq: "residential" }}}}
        }
      ) {
        nodes {
          ...PostPreview
        }
      }
      partners:allWpPost(
        limit: 2,
        sort: { fields: date, order: DESC },
        filter: {
          categories: { nodes: { elemMatch: { name:{ nin: [ "Uncategorized", "Uncategorised"] } }}},
          resourceTypes: { nodes:{ elemMatch:{ slug:{ eq: "partner" }}}}
        }
      ) {
        nodes {
          ...PostPreview
        }
      }
    }
  `)

  const { residential, partners } = data

  const [isSubmitted, setIsSubmitted] = useState(false);

  const scrollToTop = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` });
  };

  const isEnglishPage = (pageLanguage ?? 'en') === 'en';

  const { footerNav, neighbourhoodsNav } = useFooterAssets();
  const { legalNav } = useLegalAssets();

  let footerPosts = residential;

  if (footerResourcesType === 'partners') {
    footerPosts = partners
  }

  const footerNavItems = footerMenuItemsNodes[pageLanguage ?? 'en'] ?? footerNav.menuItems.nodes;
  const legalNavItems = legalNavMenuItemsNodes[pageLanguage ?? 'en'] ?? legalNav.menuItems.nodes

  const labels = footerLabels[pageLanguage ?? 'en'];

  return (
    <footer className="bg-navy-dark text-white overflow-hidden">
      <div className="container pt-24 pb-10">
        {footerCtaType === 'rental' ? (
          <FooterRentalCta showHelpFeatures />
        ) : (
          <FooterFindCta />
        )}
        <div className="grid grid-cols-12 mb-10 lg:mb-16 border-t border-white border-opacity-30 pt-10 lg:pt-20">
          <div className="col-span-12 lg:col-span-5 md:border-b lg:border-b-0 md:mb-10 pb-10 lg:border-r border-white border-opacity-30 lg:pr-10 xl:pr-20 space-y-10">
            <div className="border-b border-white border-opacity-30 pb-10">
              <h6 className="text-h6 mb-5 text-blue">{!isSubmitted ? labels.newsletterHeader : labels.newsletterHeaderSubmitted}</h6>
              {!isSubmitted && (<p className="text-opacity-80 text-white mb-5">{labels.newsletterLabel}</p>)}
              <div className={`transition-opacity duration-200 ${isSubmitted && 'opacity-25 pointer-events-none'}`}>
                <NewsletterSubscribe setIsSubmitted={setIsSubmitted} pageLanguage={pageLanguage} />
              </div>
            </div>
            <div className="">
              <h6 className="text-h6 mb-7"><Link to="/resources/" className="inline-flex items-center group hover:opacity-50 transition-opacity">{labels.blogsHeader} <span className="ml-2 w-6 h-6 rounded-full bg-yellow text-navy-dark flex justify-center items-center"><IconChevron className="transform -rotate-90 " /></span></Link></h6>
              {footerPosts.nodes.map((post, i) => (
                <PostPreview className="mb-3" key={`footerres${i}`} {...post} />
              ))}

            </div>
          </div>
          <div className="col-span-12 lg:col-span-7 lg:pl-20 lg:space-y-12">
            <div className="flex flex-wrap md:flex-nowrap lg:border-b border-white border-opacity-30 ">
              {footerNavItems.map((nav) => (
                <div key={nav.id} className="w-full md:w-1/3 lg:w-auto lg:flex-auto md:pr-4">
                  <FooterNav nav={nav} />
                </div>
              ))}
            </div>
            <div className="">
              {isEnglishPage && neighbourhoodsNav.menuItems.nodes.map((nav) => (
                <FooterNav nav={nav} key={nav.id} ulClassName="md:flex md:flex-wrap" liClassName="md:mr-4" />
              ))}
            </div>
          </div>

        </div>
        <div className="flex flex-col lg:flex-row items-center text-s">
          <p>First Floor, 10 Cromwell Pl, South Kensington, London SW7 2JN</p>
        </div>
        <div className="flex flex-col lg:flex-row items-center text-xs">
          <div className="mb-4 lg:mb-0 lg:ml-auto flex flex-col lg:flex-row items-center">
            <button className="lg:order-1 lg:ml-5 mb-8 lg:mb-0" onClick={scrollToTop}>
              <Arrow color="white" className="rotate-180 !w-12 !h-12" />
            </button>
            <nav className="">
              <ul className="flex flex-col md:flex-row md:justify-start items-center">
                {legalNavItems.map((item) => (
                  <li key={item.id} className="pl-2 mb-2  md:mb-0 md:ml-2 md:border-l first:pl-0 first:ml-0 first:border-l-0 border-white border-opacity-40">
                    <Link to={item.url} className="text-white text-opacity-50 hover:text-opacity-100 transition-all duration-200 ease-in-out">{item.label}</Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          <div className="lg:order-[-1] flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:items-center lg:space-x-6">
            <div className="flex justify-center lg:jusify-start items-center space-x-6">
              {[
                {
                  url: 'https://www.facebook.com/CityRelayLondon/',
                  type: 'facebookalt',
                },
                {
                  url: 'https://www.linkedin.com/company/city-relay-group',
                  type: 'linkedin-bg',
                },
                {
                  url: 'https://www.instagram.com/city_relay/',
                  type: 'instagram',
                },
                {
                  url: 'https://twitter.com/city_relay',
                  type: 'twitter',
                },
              ].map((item, i) => (
                <a key={item.id} href={item.url} target="_blank" className="hover:opacity-50 transition-opacity duration-300 ease-in-out">
                  <IconSocial type={item.type} className="w-5" />
                </a>
              ))}
            </div>
            <div>© {new Date().getFullYear()}. {labels.rightsReserved}</div></div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
