export const newsletterLabels = {
    "en": {
        "inputPlaceholder": "Your Email Address",
        "inputLabel": "I'm a",
        "propertyOwnerLabel": "Property owner",
        "residentLabel": "Resident"
    },
    "fr": {
        "inputPlaceholder": "Votre adresse e-mail",
        "inputLabel": "Je suis",
        "propertyOwnerLabel": "Propriétaire",
        "residentLabel": "Résident"
    },
    "es": {
        "inputPlaceholder": "Tu dirección de correo electrónico",
        "inputLabel": "Soy",
        "propertyOwnerLabel": "Propietario",
        "residentLabel": "Residente"
    }
}
