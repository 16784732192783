import { Link } from 'gatsby'
import React from 'react'

export default function Button({ url, title, target, type = 'primary', inverted = false, className, submitting, ...other }) {

  if (!url && !title)
    return null

  const Tag = url?.length > 0 ? Link : 'button';

  return (
    <Tag to={url} className={`btn btn--${type} ${inverted && `btn--${type}--inverted`} ${className}`} target={target ? target : '_self'} {...other}>
      {title}
      {submitting && (
        <div className="loader"></div>
      )}
    </Tag>
  )
}
