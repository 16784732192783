import { Link } from 'gatsby'
import React, { useState } from 'react'
import { motion } from 'framer-motion';
import IconChevron from '~/components/atoms/IconChevron';
import Button from '~/components/atoms/Button';


export default function MobileSubNavAux({secondNavItems, setSecondNav, SecondNav, secondNavLabel}) {
  const [active, setActive] = useState('');

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ type: 'tween', duration: 0.3 }}
    >
      <div className={`fixed lg:hidden overflow-auto pb-24 top-14 pt-8 px-5 right-0 bottom-0 left-0 bg-sand text-navy mobile-nav z-20`}>
        <div className='flex flex-row mb-5 items-center'>
          <div onClick={() => setSecondNav(false)}>
            <IconChevron className={`mr-4 w-4 h-auto rotate-90`} />
          </div>
          <div className='font-bold text-xl font-body text-navy'>{secondNavLabel}</div>
        </div>
        <div className="container px-0 pl-16">
          <nav className={`mt-0`}>
            <ul className="font-heading font-medium mb-6">
              {secondNavItems.map((item, i) => (
                <li key={i} className="mb-5 last:mb-0">
                  <Link
                  onClick={() => (item.childItems?.nodes?.length > 0) && setActive(active === item.label ? ' ': item.label)}
                  to={item.url} className={`tracking-tight transition-opacity duration-200 ease-in-out font-normal font-body text-navy inline-flex items-center ${active === item.label && 'opacity-50'}`}>
                    {item.label}
                    {(item.childItems?.nodes?.length > 0) && (
                      <IconChevron className={`ml-2 w-4 h-auto transform transition-transform duration-200 ease-in-out ${active === item.label && 'rotate-180'}`} />
                    )}
                  </Link>
                </li>
              ))}
            </ul>
            <Button url="https://stay.cityrelay.com/flat-listing" title="Find my home" className="absolute bottom-0 left-0 right-0 text-center" />
          </nav>
        </div>
      </div>
    </motion.div>
  )
}
