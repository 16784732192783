import React from 'react'

export default function Logo({className}) {
  return (
    <svg width="909" height="168" viewBox="0 0 909 168" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className={`fill-current ${className}`}>
      <path d="M258.058 106.93C252.955 112.6 246.434 115.719 239.346 115.719C227.155 115.719 218.083 105.796 218.083 92.1877C218.083 78.5791 227.155 68.6563 239.346 68.6563C246.434 68.6563 252.104 71.2079 257.491 77.1616L258.058 77.7286L276.486 62.419L275.919 61.852C267.697 50.795 254.656 44.8413 239.913 44.8413C211.562 44.8413 192 64.4036 192 92.1877C192 119.972 211.846 139.534 239.913 139.534C254.656 139.534 267.697 133.58 276.203 122.523L276.77 121.956L258.625 106.363L258.058 106.93Z" />
      <path d="M298.6 6.56738C289.528 6.56738 282.724 13.6552 282.724 22.7275C282.724 31.7999 289.528 38.6041 298.6 38.6041C307.673 38.6041 314.761 31.5164 314.761 22.7275C314.477 13.6552 307.389 6.56738 298.6 6.56738Z" />
      <path d="M311.358 44.2739H285.842V138.967H311.358V44.2739Z" />
      <path d="M391.025 110.049C384.788 113.734 379.401 115.719 374.865 115.719C369.194 115.719 362.39 113.167 362.39 101.544V67.2387H394.427V44.2743H362.39V17.9077H336.874V44.2743H319.864V67.2387H336.874V103.528C336.874 127.343 348.498 139.818 370.896 139.818C385.071 139.818 394.143 134.714 400.664 130.462L401.231 130.178L391.875 110.049H391.025Z" />
      <path d="M451.413 89.0689L427.598 44.5576H398.68L437.237 117.987L409.17 166.751H437.237L502.445 44.5576H474.377L451.413 89.0689Z" />
      <path d="M857.968 89.0689L834.153 44.5576H805.235L843.792 117.987L815.725 166.751H843.792L909 44.5576H880.932L857.968 89.0689Z" />
      <path d="M571.055 46.542C567.369 45.1245 563.117 44.5574 558.297 44.5574C547.807 44.5574 539.869 47.9596 534.198 54.7638V44.2739H509.249V139.25H534.765V88.2182C534.765 76.3107 541.286 69.2229 552.627 69.2229C557.446 69.2229 562.833 70.357 566.802 72.058L567.936 72.625L571.905 47.109L571.055 46.542Z" />
      <path d="M617.267 44.5576C590.333 44.5576 571.622 64.1199 571.622 92.1875C571.622 120.255 590.333 139.25 618.401 139.25C633.994 139.25 646.752 134.714 656.675 125.642L657.242 125.075L642.216 108.631L641.649 109.198C635.412 114.301 627.473 117.42 619.819 117.42C608.478 117.42 600.54 111.466 598.272 100.693H660.644V99.8423C660.928 97.8577 661.211 94.4556 661.211 91.6205C660.928 64.1199 642.783 44.5576 617.267 44.5576ZM597.988 82.8316C600.256 72.3417 606.777 66.955 616.7 66.955C626.056 66.955 632.577 72.6252 634.845 82.8316H597.988Z" />
      <path d="M696.367 6H670.851V139.25H696.367V6Z" />
      <path d="M774.616 55.3309C768.095 48.2431 759.59 44.5574 749.1 44.5574C724.151 44.5574 705.439 64.9702 705.439 91.9038C705.439 118.837 724.151 139.25 749.1 139.25C759.59 139.25 768.379 135.565 774.616 128.76V139.25H799.565V44.2739H774.616V55.3309ZM775.183 92.1873C775.183 106.079 766.111 115.719 753.353 115.719C740.311 115.719 731.522 106.079 731.522 92.1873C731.522 78.2953 740.595 68.6559 753.353 68.6559C766.111 68.6559 775.183 78.2953 775.183 92.1873Z" />
      <path d="M140.619 67.6517L128.899 57.0384C126.001 54.4708 121.533 54.588 118.872 57.2699C116.217 60.0691 116.338 64.3865 119.113 66.9512L127.694 75.5801V153.151H94.3473V122.354C94.3473 115.589 88.6678 110.108 81.6594 110.108H60.0419C53.0365 110.108 47.3541 115.589 47.3541 122.354V153.148H14.2573V76.9842L71.1544 24.2604L85.4087 36.9753C86.7376 38.2561 88.5506 38.8394 90.2433 38.8394C92.1766 38.8394 94.1068 38.1419 95.4357 36.6235C98.0936 33.8274 97.9733 29.5099 95.0749 26.9422L75.8657 9.79858L75.5049 9.4468C75.2644 9.32955 75.1441 9.21529 74.9006 9.09804C74.6601 8.98078 74.5398 8.86352 74.2963 8.74626C74.0557 8.63201 73.9355 8.51476 73.6919 8.51476C73.4514 8.3975 73.2109 8.3975 73.0906 8.28024C72.8471 8.16298 72.6035 8.04873 72.363 8.04873C72.1225 8.04873 71.882 8.04873 71.6384 7.92847H70.1892C70.069 8.04873 69.8285 8.04873 69.7052 8.04873C69.4647 8.04873 69.2241 8.16298 69.1039 8.16298C68.8603 8.28325 68.6168 8.28325 68.3763 8.3975C68.1357 8.51476 68.0155 8.63201 67.7749 8.74626C67.5314 8.86653 67.4111 8.98078 67.1676 9.09804C66.9405 9.21556 66.7367 9.37348 66.5663 9.56406L66.2025 9.91283L2.17378 69.0528C0.813653 70.3682 0.0317292 72.1699 0 74.0618L0 160.147C0 163.998 3.1419 167.032 7.12566 167.032H54.603C58.5898 167.032 61.7317 163.998 61.7317 160.147V123.993H80.096V160.147C80.096 163.995 83.2349 167.029 87.2217 167.029H135.667C139.654 167.029 142.793 163.995 142.793 160.144V72.6697C142.793 70.8056 141.948 68.9325 140.619 67.6517Z" />
      <path d="M141.101 21.8129C141.101 24.3776 138.927 26.4762 136.269 26.4762H129.989C127.331 26.4762 125.157 24.3776 125.157 21.8099V17.1467C125.157 14.579 127.331 12.4804 129.989 12.4804H136.272C138.927 12.4804 141.104 14.579 141.104 17.1467V21.8099L141.101 21.8129ZM153.548 24.1431V14.8136C153.548 6.64761 146.66 0 138.203 0H128.299C119.841 0 112.956 6.64761 112.956 14.8136V24.1431C112.956 25.8929 113.317 27.5255 113.798 29.0438L84.9285 59.8345C82.2706 62.6366 82.5112 67.0684 85.4095 69.5158C86.7164 70.6521 88.3921 71.2741 90.1239 71.2656C92.0541 71.2656 93.9874 70.4508 95.4366 68.9325L124.068 38.3733C125.397 38.7251 126.726 38.9566 128.176 38.9566H138.082C146.66 38.9566 153.545 32.309 153.545 24.1431H153.548Z" />
    </svg>
    
  )
}
