import React from 'react'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'
import IconTick from '~/components/atoms/IconTick';
import { helpFeaturesLabels } from '~/data/translations/helpFeaturesLabels';

export default function HelpFeatures({ language = 'en' }) {

  const labels = helpFeaturesLabels[language];

  return (
    <FadeInWhenVisible transition={{ delay: '.3' }} className="hidden md:flex items-center whitespace-nowrap mt-8">
      <div className="mr-4">{labels.subheader}</div>
      <ul className="flex">
        {labels.features.map((item, i) => (
          <li key={`helpFeature${i}`} className="flex items-center font-medium mr-4 last:mr-0"><IconTick className="w-5 mr-2" />{item.title}</li>
        ))}
      </ul>
    </FadeInWhenVisible>
  )
}
