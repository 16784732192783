import { Link } from 'gatsby';
import React from 'react'
import Image from '~/components/atoms/Image';

export default function PostPreview({title ,uri, featuredImage, categories, className,}) {

  return (
    <Link to={uri} className={`flex items-center group ${className}`}>
      <div className="w-24 block mr-8 flex-shrink-0 overflow-hidden">
        <div className="aspect-w-1 aspect-h-1 bg-sand">
          {featuredImage?.node && <Image data={featuredImage.node} className="!absolute w-full h-full" />}
        </div>
      </div>
      <div className="flex-auto">
        <h6 className="font-heading font-bold uppercase text-xs mb-2">{categories?.nodes[0]?.name}</h6>
        <p className="" dangerouslySetInnerHTML={{ __html: title }}/>
      </div>
    </Link>
  )
}
